import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import Services from "../components/services"

const SingleService = ({ location, pageContext: { post } }) => {
  // console.log(
  //   `Previous: ${JSON.stringify(previous)}`,
  //   `Next: ${JSON.stringify(next)}`
  // )

  location = { ...location, pathname: "/what-we-do/" }

  // console.log("location", location)
  // console.log("data", post)

  let gatsbyImageData =
    post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  let altText =
      post?.featuredImage?.node?.altText
  let additionalCopy = post?.ACF_Services?.additionalCopy

  return (
    <Layout location={location}>
      <Seo bodyClasses={`services ${post.slug}`} seo={post.seo} />
      <section className="intro">
        {gatsbyImageData && (
          <GatsbyImage image={gatsbyImageData} alt={altText} loading="eager" />
        )}
        <div className="content white">
          <div className="container">
            <div className="row">
              <div className="col-md-7 copy">{parse(post.content)}</div>
            </div>
          </div>
        </div>
      </section>

      <section className="white">
        <div className="container">
          <div className="row">
            <div className="col-md-10">{parse(post?.ACF_Services?.copy)}</div>
          </div>
        </div>
      </section>

      {additionalCopy && (
      <section className="gray-dark additional-copy">
        <div className="container">
          <div className="row grid">
             {additionalCopy.map((post, index = 1) => {
              return (
                <div className="post" key={index}>
                  <h4>{index + 1}. {post?.heading}</h4>
                  {parse(post?.details)}
                </div>
              )
              })}
          </div>
        </div>
      </section>
        )}

      <Services exclude={post.slug} />
    </Layout>
  )
}

export default SingleService

import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import parse from "html-react-parser"

const Services = ({ exclude } = "") => (
  <StaticQuery
    query={graphql`
      query {
        allWpService(sort: { fields: date, order: DESC }) {
          nodes {
            title
            excerpt
            slug            
            ACF_Services {
              copy
              additionalCopy {
                heading
                details
              }
              icon {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let {
        allWpService: { nodes: allposts },
      } = data
      const posts = allposts.filter(item => item.slug !== exclude)
      return (
        <section className="white services">
          <div className="container">
            <div className="row">
              {posts.map((post, index = 1) => {
                let icon = post?.ACF_Services?.icon?.localFile?.publicURL
                // console.log(post)
                return (
                  <div
                    className={`col-md-6 col-lg-5 post mb-5 ${
                      index % 2 === 1 ? " offset-md-0 offset-lg-1" : ""
                    }`}
                    key={index}
                  >
                    <div className="row">
                      <div className="col-1">
                        {!!icon && (
                          <img className="icon" alt={post.title} src={icon} />
                        )}
                      </div>
                      <div className="col-11">
                        <h3>{parse(post.title)}</h3>
                        {parse(post.excerpt)}
                        <p>
                          <Link to={`/${post.slug}/`} className="link arrow">
                            Read more
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      )
    }}
  ></StaticQuery>
)

export default Services
